$(document).foundation();


var badawi = {
    init: function() {
        this.doms.init();
        this.viewport.init();
        this.nodes.init();
        this.map.init();
        this.flexslider.init();
        //this.owlcarousel.init();
    },
    doms: {
        map: {},
        layers: {},
        canvas: {},
        cimg: {},
        nodes: {},
        loader:{},
        init: function() {
            this.map = $('#interactive-map');
            this.layers = $('.layer',this.map);
            this.canvas = $('.canvas',this.map);
            this.cimg = $('img',this.canvas);
            this.loader = $('<div/>',{'class':'loader-wrap'}).append($('<div/>',{'class':'loader','text':'Loading...'}));

        }
    },
    viewport: {
        width: 0,
        height: 0,
        init: function() {
            badawi.viewport.window_resize_handler();
            $(window).resize(badawi.viewport.window_resize_handler);
        },
        window_resize_handler: function() {
            badawi.viewport.height = $(window).height();
            badawi.viewport.width = $(window).width();
            badawi.map.set_height();
            Waypoint.refreshAll();

        }
    },
    map: {
        waypoints_in:{},
        waypoints_out:{},
        layers_waypoints_in:{},
        layers_waypoints_out:{},
        layer:{},
        shit: {
            '-1':{
                scale:{x:.5,y:.5},
                translate:{x: 0,y: 0},
            },
            0: {
                scale:{x:1,y:1},
                translate:{x: 0.01,y: -.2},
            },
            1: {
                scale:{x:1,y:1},
                translate:{x: 0.01,y: -.2},
            },
            2: {
                scale:{x:1,y:1},
                translate:{x: 0.01,y: -.2},
            },
            3: {
                scale:{x:1,y:1},
                translate:{x: 0.01,y: -.2},
            },
            4: {
                scale:{x:1,y:1},
                translate:{x: 0.01,y: -.2},
            },
            4: {
                scale:{x:1,y:1},
                translate:{x: 0.01,y: -.2},
            },
            4: {
                scale:{x:1,y:1},
                translate:{x: 0.01,y: -.2},
            },
        },

        init: function() {

            //fix height



            this.set_height();
            badawi.map.setup_waypoints();
            badawi.doms.map.css('max-height',badawi.viewport.height).append(badawi.doms.loader).addClass('loading').waitForImages().done(function() {

                badawi.doms.map.removeClass('loading').css('max-height','none');
                Waypoint.refreshAll();
                badawi.map.layer = badawi.doms.layers.eq(0);



            });
            $(window).scroll(badawi.map.onscroll);


        },

        update_layer:function(index){
            var acimg = badawi.doms.cimg.filter('.active');



            var old_cimg = badawi.map.layer.data('cimg');
            badawi.map.layer.removeClass('active');
            badawi.map.layer = badawi.doms.layers.eq(index).addClass('active');

            badawi.map.layer.data('from',badawi.map.shit[index-1]);
            var new_cimg = badawi.map.layer.data('cimg');
            if( old_cimg != new_cimg){
                badawi.doms.cimg.filter('.active').removeClass('active').end()
                                .filter('.'+new_cimg).addClass('active').end();
            }


        },
        set_height:function(){

            badawi.doms.canvas.height(badawi.viewport.height);
            badawi.doms.layers.height(badawi.viewport.height);

        },


        setup_waypoints:function(){
            this.waypoints_in = badawi.doms.canvas.waypoint(function(direction) {
                badawi.doms.map.removeClass('in-down in-up');
                if(direction == 'down'){
                    badawi.doms.map.addClass('in-view');
                }
                else{
                    badawi.doms.map.removeClass('in-view');
                }
                badawi.doms.map.addClass('in-'+direction);
            }, {
              offset: 'bottom-in-view'
            });

            this.waypoints_out = badawi.doms.layers.filter(':last-child').waypoint(function(direction) {
                badawi.doms.map.removeClass('out-down out-up');
                console.log(badawi.doms.map);
                if(direction == 'down'){
                    badawi.doms.map.removeClass('in-view');
                }
                else{
                    badawi.doms.map.addClass('in-view');
                }
                badawi.doms.map.addClass('out-'+direction);
            }, {
              offset: 'bottom-in-view'
            });

            /*badawi.doms.layers.each(function(i,o){
                badawi.map.layers_waypoints_in[i] =
                    $(this).waypoint(function(direction) {

                        var l = $(this.element);
                        console.log(l.index());
                        if(direction == 'down'){
                            l.addClass('l-in-view');
                        }
                        else{
                            l.removeClass('l-in-view');
                        }

                    }, {
                      offset: 'top-in-view'
                    });
                badawi.map.layers_waypoints_out[i] =
                    $(this).waypoint(function(direction) {

                        var l = $(this.element);

                        if(direction == 'down'){
                            l.removeClass('l-in-view');
                        }
                        else{
                            //l.addClass('in-view');
                        }

                    }, {
                      offset: 'bottom-in-view'
                    });
            });*/



        },

        onscroll:function(){


           //don't bother if map not in view
           if(badawi.doms.map.hasClass('in-view') ){

             var offset_top = $(window).scrollTop() - badawi.doms.map.offset().top;

            var fraction = offset_top/badawi.viewport.height;
            var behavior_index = parseInt(Math.floor(fraction));

            console.log(behavior_index);
            if(behavior_index > -1 ){
                var semi_index = fraction - behavior_index;

                if(badawi.doms.map.data('index') != behavior_index){
                    badawi.doms.map.data('index',behavior_index);

                    badawi.map.update_layer(behavior_index);
                }
                else{
                    if(behavior_index == 0){
                         badawi.doms.layers.removeClass('l-in-view');
                    }
                    if(semi_index>.5){
                        badawi.doms.layers.removeClass('l-in-view').eq(behavior_index+1).addClass('l-in-view');
                    }
                }

            }
            else{
                badawi.doms.layers.removeClass('l-in-view');
            }

            if(typeof badawi.map.shit[behavior_index] != 'undefined'){


               var behavior = badawi.map.shit[behavior_index];
               var wwa = (offset_top/badawi.viewport.height)-behavior_index;//where we at
               badawi.map.draw(behavior,wwa);
            }

           }
           //requestAnimationFrame(badawi.map.onscroll);


        },

        draw:function(behavior, percentage){
            var obj = badawi.doms.cimg,
                layer = badawi.map.layer;

            var transform = '';
            var from = layer.data('from'),
                oscale = from.scale,
                otranslate = from.translate;

            if(typeof behavior.scale.x != 'undefined'){
                transform+= ' scale('+( oscale.x + (behavior.scale.x - oscale.x) * percentage)+')';
            }
            else{
                transform+= ' scale('+oscale.x+')';
            }
            if(typeof behavior.translate.x != 'undefined'){

                transform+= ' translateX('+((otranslate.x + (behavior.translate.x - otranslate.x) * percentage)*100)+'%)';
            }
            else{
                transform+= ' translateX('+otranslate.x+')';
            }
            if(typeof behavior.translate.y != 'undefined'){
                transform+= ' translateY('+((otranslate.y + (behavior.translate.y - otranslate.y) * percentage)*100)+'%)';
            }
            else{
                transform+= ' translateY('+otranslate.y+')';
            }
            if(transform.length>0){
                obj.css({'transform':transform});
                //$('img',layer).css({'transform': transform});
            }

        },

    },

    nodes:{
        nodes:{},
        images:{},
        init:function(){
            this.nodes = $('.node','.l-content');
            this.images = this.nodes.filter('.image');
            this.images.height(badawi.viewport.height * .66);
        },

    },
    flexslider:{
        sliders: {},
        waypoints_in:{},
        waypoints_out:{},
        settings:{

            animation: "slide",
            animationLoop: false,
            itemWidth: 320,
            itemMargin: 0,
            start:function(slider){
                var slides = $('.slides',slider);
                var tmp = $('.slides',slider).width();
                slides.width('auto');//.width(tmp);
                setTimeout(function(slides,tmp){slides.width(tmp)},50,slides,tmp);
            }
        },

        init:function(){
            this.sliders = $('.slider').addClass('flexslider');
            this.setup_sliders();
            this.setup_waypoints();

        },
        setup_sliders:function(){
            this.sliders.flexslider(this.settings);
        },
        setup_waypoints:function(){
            this.waypoints_in = this.sliders.waypoint(function(direction) {
                var flexslider = $(this.element).data('flexslider');
                if(direction == 'down'){



                }
                else{

                }

            }, {
              offset: 'bottom-in-view'
            });


        },
    },


};


$(document).ready(function() {
    badawi.init();
});

